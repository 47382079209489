import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SliceList from '../components/sliceList'
import { withUnpublishedPreview } from 'gatsby-source-prismic'
import Page from '../templates/page'
import CaseStudy from '../templates/caseStudy'

export const query = graphql`
  query page404 {
    prismicPage(uid: { eq: "404" }) {
      ...PrismicPageFragment
    }
  }
`

const NotFoundPage = ({ data }) => {
  const pageData = data.prismicPage.data
  return (
    <Layout>
      <Seo title={pageData.meta_title ? (pageData.meta_title):(pageData.title)} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      {pageData.body !== null && pageData.body.length > 0 && pageData.body.map((slice, index) => {
        return (
          <SliceList slice={slice} key={`slice-${index}`} />
        )
      })}
    </Layout>
  )
}

export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    page: Page,
    case_study: CaseStudy
  },
})
